import React from 'react';
import {
  Layout,
  Module,
  Row,
  Column,
  BlogPostIntro,
  BlogPostTagsFooter,
  BlockContent,
  FeaturedArticles as RelatedArticles
} from '..';
import article from '../content/article/article.json';
import content from '../content/article/article-content.json';

export default function Article() {
  const { postIntro, footerTags, relatedArticles } = article;

  return (
    <Layout>
      <Module marginTop={['xl', 'xxl']}>
        <BlogPostIntro intro={postIntro} />
      </Module>
      <Module marginTop={['md', 'xxl']}>
        <Row cols={[21, 13]} offset={[2, 6]}>
          <Column>
            <BlockContent blocks={content} />
          </Column>
        </Row>
      </Module>
      <Module>
        <BlogPostTagsFooter tags={footerTags} />
      </Module>
      <Module>
        <RelatedArticles {...relatedArticles} />
      </Module>
    </Layout>
  );
}
